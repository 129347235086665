import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function BasicDatePicker(props) {
  //TODO add only selectioninstaed of text edit as done for device id select
  return (
    <div className="BasicDatePicker">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="BasicDatePicker-DatePicker">
          <DatePicker
            label="Date"
            value={props.selectedDate}
            onChange={props.handleChange}
            disableFuture={true}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField {...params} sx={{ minWidth: 250 }} />
            )}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
}
