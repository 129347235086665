import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export default function StaticTimePickerLandscape(props) {
  return (
    <div className="StaticTimePickerLandscape">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="StaticTimePickerLandscape-TimePicker">
          {
            <TimePicker
              label="Time"
              value={props.selectedTime}
              onChange={props.handleChange}
              renderInput={(params) => (
                <TextField {...params} sx={{ minWidth: 250 }} />
              )}
              ampm={false}
              minutesStep={5}
            />
          }
        </div>
      </LocalizationProvider>
    </div>
  );
}
